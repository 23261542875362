import React, { useEffect } from "react";
import SocialRow from "../SocialRow";
import ImageHelper from "../ImageHelper";
import profileImg from '../../images/profile.jpg';
import styles from './IntroductionCard.module.css';

const IntroductionCard = ({ person }) => {

  useEffect(() => {
    console.log(person);
  }, [])

  return (
    <div className={styles.introductionHolder}>
      <div className={styles.introductionCard}>
        <div className={styles.introHolder}>
          <div className={styles.imageHolder}>
            <img src={profileImg} className={styles.introImage} />
          </div>
          <div className={styles.introInfo}>
            <div className={`${styles.introText} ${styles.introName}`}>{person.name}</div>
            <div className={styles.socialRow}><SocialRow  /></div>
            <div className={styles.introText}>423.921.4457</div>
            <div className={styles.introText}>drewmichel1995@gmail.com</div>
            <div className={styles.introText}>{person.location}</div>
            <div className={styles.introRoles}><b>Roles:</b> Software Engineer, DevOps Engineer, Cloud Engineer, System Administrator</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionCard;
