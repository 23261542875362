import React from 'react';
import { StyleSheetManager } from 'styled-components';
import styles from './CardIndicator.module.css';

const CardIndicator = ({numCards, index}) => {
    return (
        <div className={styles.indicatorHolder}>
        {numCards.map((num, idx)=> (
            
            idx === index ?  <div className={styles.selectedIndicator}></div> : <div className={styles.indicator}></div>
            
        ))}
        </div>
    )
}

export default CardIndicator;