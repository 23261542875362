import React, { useState, useRef, useEffect } from "react";
import ReadMoreReact from "read-more-react";
import ImageHelper from "../ImageHelper";
import StackComponent from "./StackComponent";
import DevIcon from 'devicon-react-svg';
import styles from "./ExperienceCard.module.css";
import useOnScreen from "../../hooks/useOnScreen";
import useIntersection from "../../hooks/useIntersection";
import CardIndicator from "../CardIndicator/CardIndicator";

const toppad = window.innerWidth < 480 ? "0rem" : "0rem";

const ExperienceCard = ({ experience }) => {
  const [show, setShow] = useState(false);
  const [wiggleClass, setWiggleClass] = useState()
  const [currentCard, setCurrentCard] = useState(0);
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    console.log(isVisible)
    if(isVisible) setWiggleClass(styles.wiggle)
    
  }, [isVisible])

  return (
    <div className={styles.experienceHolder}>
      <div className={styles.experienceHeader}>Experience</div>
      <CardIndicator index={currentCard} numCards={experience}/>
      <div className={styles.experienceContent} ref={ref}>
        {experience.map((exp, index) => (
          <Card exp={exp} wiggleClass={wiggleClass} setIndex={() => setCurrentCard(index)}/>
        ))}
      </div>
    </div>
  );
};

const Card = ({exp, wiggleClass, setIndex}) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, '-150px');
  useEffect(() => {
    if(inViewport) setIndex();
  }, [inViewport])

  return (
    <div key={exp.mode + '-exp-card' + exp.jobTitle} className={`${styles.experienceCard} ${wiggleClass}`} ref={ref}>
            <div className={styles.cardHeader}>
              <ImageHelper className={styles.cardImage} mode={exp.mode} />
              <div className={styles.cardSubtitle}>{exp.jobTitle}</div>
              <div className={styles.cardSubtitle}>{exp.dates}</div>
              <div className={styles.cardSubtitle}>{exp.location}</div>
            </div>
            <ExperienceBody exp={exp} />
          </div>
  )
}

const ExperienceBody = ({ exp }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={styles.tabHolder}>
        <btn className={styles.cardTab} onClick={() => setShow(false)}>
          Description
        </btn>
        <btn className={styles.cardTab} onClick={() => setShow(true)}>
          Technologies
        </btn>
      </div>
      <div className={styles.cardBody}>
        {!show && (
          <div className={styles.cardDescription}>{exp.description}</div>
        )}
        {show && (
          <div className={styles.cardDescription}>
            {exp.skills.map(skill => (
              <div className={styles.techHolder}>
                <div className={styles.techLabel}>{skill.name}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ExperienceCard;