import React, { useEffect, useState, useRef } from "react";
import DevIcon, {iconList} from "devicon-react-svg";
import styles from './Skills.module.css';
import CardIndicator from '../CardIndicator/CardIndicator';
import useOnScreen from "../../hooks/useOnScreen";
import useIntersection from "../../hooks/useIntersection";

const ExpandedSkillsCard = ({ data }) => {
  const [skillClass, setSkillClass] = useState(styles.skillSet)
  const [currentCard, setCurrentCard] = useState(1);
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    console.log(isVisible)
    if(isVisible) setSkillClass(styles.skillsWiggle)
    
  }, [isVisible])

  const setIndex = (index) => {
    setCurrentCard(index)
  }

  return (
    <div id="skills" className={styles.skillsHolder}>
      <div className={styles.skillsHeader} >Skills</div>
      <CardIndicator index={currentCard} numCards={data} />
      <div className={styles.skills} ref={ref}>
      {data.map((skill, index) => (
       <Card skill={skill} skillClass={skillClass} setIndex={() => setIndex(index)} index={index}/>
      ))}
      </div>
    </div>
  );
};

const Card = ({skill, skillClass, setIndex, index}) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, '-150px');
  useEffect(() => {
    if(inViewport) {
      // console.log(`X Position: ${ref.current.getBoundingClientRect().x}`)
      // console.log(`Component Width: ${ref.current.getBoundingClientRect().width}`)
      // console.log(`Window inner Width: ${window.innerWidth}`)
      setIndex();
    }
  }, [inViewport])

  return (
    <div key={skill.title + index + '-skill-card'} className={skillClass} ref={ref}>
    <div className={styles.skillsTitle}>{skill.title}</div>
    <ul className={styles.skillList}>
    {skill.fields.map(item => (
      <li className={styles.skillListItem}>
        <div className={styles.skill}>
        <DevIcon icon={item.icon} className={styles.skillIcon} />
        <div className={styles.skillName}>{item.name}</div>
        </div>
      </li>
    ))}
    </ul>
  </div>
  )
}

export default ExpandedSkillsCard;