export function getPortfolio() {
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "CMc5r8dE9R5pyGlbRVG2V4jEx0cUpk4H9Zb3c84C");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch("https://ricopky3tc.execute-api.us-east-2.amazonaws.com/default/getPortfolio", requestOptions).then(result => result.json())
  }