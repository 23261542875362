import React from "react";
import { Row } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";
import EmailModal from "./EmailModal";

const SocialRow = ({className}) => {
  return (
    <Row className="nopadding justify-content-center">
      <SocialIcon
        url="https://github.com/drewmichel1995"
        className={className || "social-row"}
        bgColor="#FCBF49"
      />

      <SocialIcon
        url="https://www.linkedin.com/in/drew-michel-4a1766123"
        className={className || "social-row"}
        bgColor="#FCBF49"
      />

      <EmailModal />
    </Row>
  );
};

export default SocialRow;
