import React, { useState, useEffect }from 'react';
import { Col } from 'react-bootstrap';
import IntroductionCard from '../IntroductionCard/IntroductionCard';
import SkillsCard from '../SkillsCard/SkillsCard';
import ExperienceCard from '../ExperienceCard/ExperienceCard';
import ProjectCard from '../ProjectCard/ProjectCard';
import EducationCard from '../EducationCard/EducationCard';
import { getPortfolio } from '../../methods/getPortfolio';
import styles from './AboutMe.module.css'
import Loader from '../Loader/Loader';

export default function AboutMe() {

  const [profile, setProfile] = useState(null);
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [education, setEducation] = useState([]);

  

  useEffect(() => {
    
    getPortfolio().then((result) => {
        result = result.body;
        console.log(result);
        setProfile(result.profile);
        setSkills(result.skills);
        setExperience(result.experience);
        setEducation(result.education);
        setProjects(result.projects);
       
      });
  },[])

  

    return (
      <div className={styles.AboutMe} >
        
        {profile ?
        
        (<div className={styles.aboutHolder} >
          
          <div className="scrollpointcenter top-content"><IntroductionCard person={profile} /></div>

          <div className="scrollpointcenter middle-content"><SkillsCard data={skills} /></div>

          <div className="scrollpointcenter middle-content"><ExperienceCard experience={experience} /></div>

          <div className="scrollpointcenter middle-content"><EducationCard education={education} /></div>

          <div className="scrollpointcenter middle-content bottom-content"><ProjectCard projects={projects} /></div>
        </div>  ) : (
          <Loader></Loader>
        )
       
        }
      </div>
    )
  
}

