import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import ImageHelper from "../ImageHelper";
import styles from "./ResumeViewer.module.css";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ResumeViewer() {
  const [show, setShow] = useState(false);
  const modal = useRef();
  const modalContent = useRef();
  const { height, width } = useWindowDimensions();
  useOnClickOutside(modalContent, () => {
    setShow(false);
  });

  useEffect(() => {
    modal.current.className = show ? styles.modalShow : styles.modal;
  }, [show]);

  const openResume = () => {
    window.open("https://drewmichel-portfolio-resources.s3.us-east-2.amazonaws.com/Drew.Michel.Resume.pdf")
  }

  const onResumeClick = () => {
    width < 900 ? openResume() : openResume()
  }

  //"https://drewmichel-portfolio-resources.s3.us-east-2.amazonaws.com/Drew.Michel.Resume.pdf"
  //https://drewmichel-portfolio-resources.s3.us-east-2.amazonaws.com/Drew.Michel.Resume.docx
  return (
    <>
      <a onClick={() => onResumeClick()}>Resume</a>

      <div ref={modal} id="myModal" class={styles.modal}>
        <div ref={modalContent} class={styles.modalContent}>
          <div className={styles.modalHeader}>
            Resume
            <span class={styles.close} onClick={() => setShow(false)}>
              &times;
            </span>
          </div>
          <div className={styles.modalBody}>
            <ImageHelper className={styles.modalBody} mode="resume" borderRadius="0%" width="30rem"/>
          </div>
          <div className={styles.modalFooter}>
            <btn className={styles.modalButton} onClick={() => openResume()}>View</btn>
          </div>
        </div>
      </div>
    </>
  );
}
