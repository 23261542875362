import React, { useState, useEffect } from "react";
import { Route, NavLink, HashRouter } from "react-router-dom";
import AboutMe from "./components/AboutMe/AboutMe";
import Thpace from "../node_modules/thpace/thpace.js";
import PortfolioNavbar from "./components/PortfolioNavbar/PortfolioNavbar";
import ScrollProgress from './components/ScrollProgress/ScrollProgress';
import useScrollPercentage from "./hooks/useScrollPercentage";

//003049 d62828 f77f00 fcbf49 eae2b7
function App() {
  const [scrollRef, scrollPercentage] = useScrollPercentage();

  useEffect(() => {
    const canvas = document.getElementById("background");

    const settings = {
      colors: ["#003049", "#d62828", "#d62828", "#003049"],
      maxFps: 30,
      triangleSize: 180,
      pointAnimationSpeed: 20000,
    };

    Thpace.create(canvas, settings);
  }, []);

  return (
    <>
      <div id="bg">
        <canvas id="background"></canvas>
      </div>
      <div>
        <PortfolioNavbar name="Drew Michel" />
        <ScrollProgress width={scrollPercentage} />
        <div className="scrollcontainer" ref={scrollRef}>
          <AboutMe />
        </div>
      </div>
    </>
  );
}

export default App;
