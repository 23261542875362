import React, { useEffect, useState } from "react";
import useScrollPercentage from "../../hooks/useScrollPercentage";
import styles from "./ScrollProgress.module.css";

const ScrollProgress = (props) => {
    const [width, setWidth] = useState(0)
    useEffect(() => {
        let temp = Math.ceil(props.width/20);
        setWidth(temp*20);
    }, [props.width])

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={{width: width + '%'}} id="myBar"></div>
    </div>
  );
};

export default ScrollProgress;
