import React from "react";
import styles from "./Loader.module.css";

const Loader = (props) => {
  return (
    <div className={styles.loaderHolder}>
      <div className={styles.loaderWrapper}>
        <div className={styles.loader}>
          <div className={`${styles.loader} ${styles.loaderInner}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
