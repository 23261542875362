import React, {useState, useRef, useEffect} from "react";
import DevIcon from "devicon-react-svg";
import StackComponent from "../ExperienceCard/StackComponent";
import styles from "./ProjectCard.module.css";
import "../style.css";
import useOnScreen from "../../hooks/useOnScreen";
import useIntersection from "../../hooks/useIntersection";
import CardIndicator from "../CardIndicator/CardIndicator";

const ProjectCard = ({ projects }) => {
  const [currentCard, setCurrentCard] = useState(0);
  const [wiggleClass, setWiggleClass] = useState()
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    console.log(isVisible)
    if(isVisible) {
      setWiggleClass(styles.wiggle)
    }
    
  }, [isVisible])


  return (
    <div className={styles.projectHolder}>
      <div className={styles.projectHeader}>Projects</div>
      <CardIndicator numCards={projects} index={currentCard}/>
      <div className={styles.projectContent} ref={ref}>
        {projects.map((project, index) => (
          <Card project={project} wiggleClass={wiggleClass} setIndex={() => setCurrentCard(index)} />
        ))}
      </div>
    </div>
  );
};

const Card = ({project, wiggleClass, setIndex}) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, '-150px');
  useEffect(() => {
    if(inViewport) setIndex();
  }, [inViewport])

  return (
    <div className={`${styles.projectCard} ${wiggleClass}`} ref={ref}>
    <div className={styles.projectCardHeader}>
      <div className={styles.projectTitle}>{project.title}</div>
      <div
        className={styles.projectLink}
        onClick={() => window.open(project.githubLink)}
      >
        <button className={styles.projectLinkBtn}>Code</button>
      </div>
    </div>
    <div className={styles.projectBody}>
      <div className={styles.projectStack}>
        {project.stack.map((item) => (
          <DevIcon icon={item.icon} style={{ width: "3rem" }} />
        ))}
      </div>
      <div className={styles.projectDescription}>
        {project.description}
      </div>
    </div>
  </div>
  )
}

export default ProjectCard;