import React from 'react';
import ExpandedSkillsCard from './ExpandedSkillsCard';
import CompactSkillsCard from './CompactSkillsCard';


const SkillsCard = ({ data }) => {
  return (

        <ExpandedSkillsCard data={data} />

  );
};

export default SkillsCard;
