import React, {useState, useRef, useEffect} from "react";
import ImageHelper from "../ImageHelper";
import styles from "./EducationCard.module.css";
import useOnScreen from "../../hooks/useOnScreen";
import useIntersection from "../../hooks/useIntersection";
import CardIndicator from "../CardIndicator/CardIndicator";
import {Modal} from 'react-bootstrap'


const toppad = window.innerWidth < 480 ? "0rem" : "0rem";

const EducationCard = ({ education }) => {
  const [currentCard, setCurrentCard] = useState(0);
  const [wiggleClass, setWiggleClass] = useState()
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    console.log(isVisible)
    if(isVisible) {
      setWiggleClass(styles.wiggle)
    }
    
  }, [isVisible])

  return (
    <div className={styles.educationHolder}>
      <div className={styles.educationHeader}>Education</div>
      <CardIndicator numCards={education} index={currentCard} />
      <div className={styles.educationContent} ref={ref}>
        {education.map((edu, index) => (
          <Card edu={edu} wiggleClass={wiggleClass} setIndex={() => setCurrentCard(index)} />
        ))}
      </div>
    </div>
  );
};

const Card = ({edu, wiggleClass, setIndex}) => {
  const ref = useRef();
  const inViewport = useIntersection(ref, '-150px');
  useEffect(() => {
    if(inViewport) setIndex();
  }, [inViewport])

  return (
    <div className={`${styles.educationCard} ${wiggleClass}`} ref={ref}>
            <div className={styles.educationCardHeader}>
              <ImageHelper className={styles.educationImage} mode={edu.mode} />

              <div className={styles.educationInfo}>
                <div className={styles.educationTitle}>{edu.organization}</div>
                <div className={styles.educationSubtitle}>{edu.credential}</div>
                <div className={styles.educationSubtitle}>{edu.dates}</div>
                <div className={styles.educationSubtitle}>{edu.location}</div>
              </div>
            </div>
            <div className={styles.educationBody}>
              {edu.organization === "Tennessee Tech University" && (
                <DegreeViewer />
              )}
              <div className={styles.educationDescription}>
                {edu.description}
              </div>
            </div>
          </div>
  )
}

const DegreeViewer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className={styles.degreeButton} onClick={handleShow}>
        Degree
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="email-color">
          <Modal.Title className="white-text">Degree</Modal.Title>
        </Modal.Header>
        <Modal.Body className="justify-content-center bg-gainsboro">
          <ImageHelper mode="degree" borderRadius="none" width="100%" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EducationCard;

//<div className="section-header card-bottom">
//   <h2 id="education" className="white-text">
//     Education
//   </h2>
//   <hr />
//   <Carousel interval={null} controls={false} touch={true}>
//     {education.map(data => (
//       <Carousel.Item >
//         <Row className="justify-content-center experience-card">
//           <Col className="card-col">
//             <Tab.Container
//               id="left-tabs-example"
//               defaultActiveKey="description"
//             >
//               <Card
//                 style={{ borderRadius: '1rem' }}
//                 className="align-items-center content-card"
//               >
//                 <Card.Header
//                   className="experience-card-header"
//                   style={{ overflow: 'hidden' }}
//                 >
//                   <Row className="justify-content-center align-items-center">
//                     <Col xs="auto">
//                       <ImageHelper mode={data.mode} />
//                     </Col>
//                     <Col

//                       xs="auto"
//                     >
//                       <Row className="justify-content-center align-items-center">
//                         <Card.Title className="text-align-center">
//                           {data.organization}
//                         </Card.Title>
//                       </Row>
//                       <Row className="justify-content-center align-items-center">
//                         <Card.Subtitle className="mb-2 no-wrap text-align-center">
//                           {data.credential}
//                         </Card.Subtitle>
//                       </Row>
//                       <Row className="align-items-center justify-content-center">
//                         <Card.Subtitle className="mb-2 text-muted no-wrap text-align-center">
//                           {data.dates}
//                         </Card.Subtitle>
//                       </Row>
//                       <Row className="align-items-center justify-content-center">
//                         <Card.Subtitle className="text-muted no-wrap text-align-center">
//                           {data.location}
//                         </Card.Subtitle>
//                       </Row>
//                       {data.organization ===
//                         'Tennessee Tech University' && (
//                         <Row className="align-items-center justify-content-center">
//                           <DegreeViewer />
//                         </Row>
//                       )}
//                     </Col>
//                   </Row>
//                 </Card.Header>
//                 <Card.Body className="scroll-card-container">

//                       <Card.Text className="scroll">

//                           {data.description}

//                       </Card.Text>

//                 </Card.Body>
//               </Card>
//             </Tab.Container>
//           </Col>
//         </Row>
//       </Carousel.Item>
//     ))}
//   </Carousel>
// </div>
