import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import SocialRow from "../SocialRow";
import ImageHelper from "../ImageHelper";
import ResumeViewer from "../ResumeViewer/ResumeViewer";
import styles from "./PortfolioNavbar.module.css";
import { Link, animateScroll as scroll } from "react-scroll";

function PortfolioNavbar({ name }) {
  const [expandNav, setExpand] = useState(false);

  return (
    <div className={styles.portfolioNavbar}>
      <div className={styles.navbarBrand}>
        <ImageHelper mode="icon" width="3rem" />{" "}
        <div className={styles.navbarTitle}>{name}</div>
        <div className={styles.navItem}>
          <ResumeViewer />
        </div>
        <div className={styles.endNavItem}>
          <SocialRow />
        </div>
      </div>
    </div>
  );
}

export default PortfolioNavbar;
